import { GoogleMap } from '@react-google-maps/api';
import React, { Component } from 'react';
import GoogleMaps from './Map'

class Contact extends Component {
  render() {
    return (
      <section id="contact">
         
         <div className="row">
            
            <div className="eight columns">

           <div id="message-warning"> Error boy</div>
				   
           </div>
           <div className="google-maps">
            <GoogleMaps />
           </div>
            <div id="contact-info" className="four columns footer-widgets">
               <div className="widget widget_contact">
					   <h4>Address and Phone</h4>
					   <p className="address">
						   #2008 - 88 W Pender St <br />
						   Vancouver, BC V3N 1R9<br />
						   <span> Tel: +1 (604) - 291- 7990</span>
					   </p>
				   </div>

               <div className="widget widget_tweets">
                  <h4 className="widget-title"> Opening Hours </h4>
                  <ul id="twitter">
                     <li>
                        Monday : 10 AM - 6 PM 
                     </li>
                     <li>
                        Tuesday : 10 AM - 6 PM 
                     </li>
                     <li>
                        Wednesday : 10 AM - 6 PM 
                     </li>
                     <li>
                        Thursday : 10 AM - 6 PM 
                     </li>
                     <li>
                        Friday : 10 AM - 6 PM 
                     </li>
                     <li>
                        Saturday : 10 AM - 6 PM 
                     </li>
                     <li>
                        Sunday & Public Holidays :  CLOSED
                     </li>
                  </ul>
		         </div>
            </div>
      </div>
   </section>
    );
  }
}

export default Contact;

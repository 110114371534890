import React, { Component } from 'react';

class Header extends Component {
   onClick = (e) => {
      e.preventDefault()
      window.open("https://www.ubereats.com/ca/vancouver/food-delivery/bali-thai/Q8KEFALrRM6n5iuroqz-oA")
   }
  render() {
    return (
      <header id="home" style={{maxHeight:"550px"}}>

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
            <li><a className="smoothscroll" href="#about">About Us</a></li>
	         <li><a className="smoothscroll" href="#resume">Menu</a></li>
            <li><a className="smoothscroll" href="#contact">Location</a></li>
            <li><a className="smoothscroll" href="#" onClick={this.onClick}>Order Online</a></li>
         </ul>

      </nav>

      <p className="scrolldown">
         <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;

import React, { Component } from 'react'
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
 
export class GoogleMaps extends Component {
  render() {
    return (
      <Map 
        google={this.props.google} 
        zoom={17} 
        initialCenter={{lat: 49.280331, lng: -123.106221}}
      >
          <Marker 
              position={{lat: 49.280331, lng: -123.106221}}
          />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_KEY
})(GoogleMaps)
import React, { Component } from 'react';

class Menu extends Component {
  render() {
    if(this.props.data){
      var weeklySpecial = this.props.data.weeklySpecial.map(function(weeklySpecial){
        return (
          <div> 
            <h6> <span style={{float: "right"}}> {weeklySpecial.price1} </span> </h6>
            <h5> {weeklySpecial.item} </h5>
            <h6> <span style={{float: "right"}}> {weeklySpecial.price2} </span> </h6>
            <p> {weeklySpecial.description} </p>
          </div>
        )
      })

      var madeToOrder = this.props.data.madeToOrderSpecials.map((madeToOrderSpecials) => {
        return (
          <div>
            <h6> <span style={{float: "right"}}> {madeToOrderSpecials.price} </span> </h6>
            <h5> {madeToOrderSpecials.item} </h5>
            <p> {madeToOrderSpecials.description} </p>
          </div>
        )
      })

      var desserts = this.props.data.dessert.map((dessert) => {
        return (
          <div>
            <h6> <span style={{float: "right"}}> {dessert.price} </span> </h6>
            <h5> {dessert.item} </h5>
            <p> {dessert.description} </p>
          </div>
        )
      })
      
    }
    return (
      <section id="resume" style={{paddingBottom: 0}}>

      <div className="row education">
         <div className="three columns header-col">
            <h1> Bali Thai Specials </h1>
         </div>
         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {weeklySpecial}
               </div>
            </div>
         </div>
      </div>


    <div className="row work">
      <div className="three columns header-col">
          <h1> Made To Order Special </h1>
      </div>
      <div className="nine columns main-col">
        {madeToOrder}
      </div>
    </div>
    
    <div className="row work">
      <div className="three columns header-col">
        <h1> Build Your Own Special </h1>
      </div>
      <div className="nine columns main-col" >
        <h6> <span style={{float:"right"}}> Regular: $10.99</span> </h6>
        <h5> Step 1: Choose Your Rice </h5>
        <h6> <span style={{float:"right"}}> Large: $11.99</span> </h6>
        <span> 
          <ul>
            <li > Coconut Rice </li>
            <li > Steamed Rice </li>
          </ul>
        </span>
      </div>
      <div id="proteinchoice" className="nine columns main-col" /*style={{float:"right"}}*/>
        <h5> Step 2: Choose Your Protein </h5>
        <span /*style={{float:"left"}}*/> 
          <ul>
            <li > Beef rendang </li>
            <li > Lemongrass Curry Chicken </li>
            <li > Spicy Balinese Chicken </li>
            <li > Grilled Fish </li>
            <li > Grilled Chicken with Green Chilli Sauce </li>
            <li > Egg Balado </li>
            <li > Tempeh Orek </li>
          </ul>
        </span>  
      </div>
      <div id="vege-choice" className="nine columns main-col" /*style={{float:"right"}}*/>
        <h5> Step 3: Choose Your Vegetables </h5>
        <span /*style={{float:"left"}}*/> 
          <ul>
            <li > Seasonal Vegetables </li>
            <li > Grilled Eggplant served with Peanut Sauce </li>
            <li > Steamed Kale served with Peanut Sauce </li>
          </ul>
        </span>  
      </div>
    </div>
    <div className="row work" style={{marginBottom: 0}}>
      <div className="three columns header-col">
        <h1> Drinks and Dessert </h1>
      </div>
      <div className="nine columns main-col"> 
        {desserts}
      </div>
    </div>

   </section>
    );
  }
}

export default Menu;

import React, { Component } from 'react';

class Footer extends Component {
  onClick = (e) => {
    e.preventDefault()
    window.open("https://www.instagram.com/balithaivancouver/")
   }

  styleIcon = {
    textWeight: "bold",
    textDecorationLine: 'underline',
    paddingBottom:"2%",
  }
   
  render() {
    if(this.props.data){
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
      <footer>

     <div className="row">
        <div className="twelve columns">
           <ul className="social-links" style={{marginBottom:"0"}}>
              {networks} 
           </ul>
           <h5 style={this.styleIcon} onClick={this.onClick}> <a href="balithai"> #BALITHAIVANCOUVER </a></h5>
           <ul className="copyright">
              <li>&copy; Copyright 2020 Bali Thai</li>
              <li>Design by <a title="Styleshout" href="http://www.styleshout.com/">Styleshout</a></li>
           </ul>

        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
     </div>
  </footer>
    );
  }
}

export default Footer;
